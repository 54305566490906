<template>
  <Modal :show_modal="show_modal" name_modal="list_nft" style_modal="height:35%;width:300px;min-height:300px">
    <template v-slot:title>Listing</template>
    <template v-slot:body>
      <input class="form-control" type="number" v-model="price" placeholder="Price"/>
    </template>
    <template v-slot:footer>
      <button @click="list_nft">Validate</button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/Modal.vue';
import { list_nft_program } from "@/libs/rustProgram";
import axios from "axios";
import {cancel_list_program} from "../../libs/rustProgram";
import {getWalletAddress} from '@/libs/Wallet.js';
import {getSolanaObject} from "../../libs/Wallet";

export default {
	name: 'ModalListNft',
  components: {
		Modal
	},
	props: {
		
		show_modal: Boolean,
    nft:{},
	},
	data:function(){
		return{
      price: Number,
		}
	},
	methods:{

      list_nft : function(){

        var componant = this;

        var nft_id = componant.nft.id;

        if(getWalletAddress() == false) {
          componant.$root.$emit('show_modal', ['connect_wallet', true]);

        }
        else{

          componant.$root.$emit('loading_action_on_nft',{'nft_id': nft_id,'loading' : true});

          componant.$root.$emit('show_modal', ['list_nft', false]);

          componant.list_nft_call_program(nft_id);

        }
      },

    async list_nft_call_program(nft_id){

      var componant = this;

      var response = true;

      response = await list_nft_program(getSolanaObject(), componant.price, componant.nft.mint_address).catch(
        (error) => {
          response = "Error while confirming transaction, please refresh and try again !";
        }
      );

      if(response !== true){

        componant.$root.$emit('loading_action_on_nft',{'nft_id': nft_id,'loading' : false});

      componant.$dtoast.pop({
        preset: "error",
        heading: `Error`,
        content:  `Your NFT has not been listed, please try again !`,
      });


      }

      else {

        setTimeout(function () {

          componant.$dtoast.pop({
            preset: "success",
            heading: `Success`,
            content:  `Your NFT is now listed !`,
          });

          componant.$root.$emit('change_list_nft',nft_id);

          componant.$root.$emit('loading_action_on_nft',{'nft_id': nft_id,'loading' : false});

        }, 2500);

      }

    }

	},

  watch: {
    show_modal: function () {
      this.price = '';
    }
  },
}
</script>

