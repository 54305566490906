<template>
  <Modal :show_modal="show_modal" name_modal="list_cancel_nft" style_modal="height:35%;width:300px;min-height:300px">
    <template v-slot:title>Cancel List</template>
    <template v-slot:body>
      <p>Are you sure you want to unlist your NFT ?</p>
    </template>
    <template v-slot:footer>
      <button @click="unlist_nft">I'm sure !</button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/Modal.vue';
import { cancel_list } from "@/libs/rustProgram";
import {cancel_list_program} from "../../libs/rustProgram";
import {getWalletAddress} from '@/libs/Wallet.js'
import {getSolanaObject} from "../../libs/Wallet";

export default {
	name: 'ModalListCancelNft',
  components: {
		Modal
	},
	props: {

		show_modal: Boolean,
    nft:{},
	},
	data:function(){
		return{
      price: Number,
		}
	},
	created: function() {


	},
	methods:{

    unlist_nft(){

      var componant = this;

      var nft_id = componant.nft.id;

      if(getWalletAddress() == false) {
        componant.$root.$emit('show_modal', ['connect_wallet', true]);

      }
      else{

        componant.$root.$emit('loading_action_on_nft',{'nft_id': nft_id,'loading' : true});

        componant.$root.$emit('show_modal', ['list_cancel_nft', false]);

        componant.unlist_nft_call_program(nft_id);

      }

    },

    async unlist_nft_call_program(nft_id){

      var componant = this;

      var response = true;

      response = await cancel_list_program(getSolanaObject(), componant.nft).catch(
          (error) => {
            response = "Error while confirming transaction, please refresh and try again !";
          }
      );

      if (response !== true) {

        componant.$root.$emit('loading_action_on_nft',{'nft_id': nft_id,'loading' : false});

		componant.$dtoast.pop({
			preset: "error",
			heading: `Error`,
			content:  `Your NFT has not been unlisted, please try again !`,
		});

        return false;

      } else {

        setTimeout(function () {

          componant.$dtoast.pop({
            preset: "success",
            heading: `Success`,
            content: `The NFT have been correctly unlisted !`,
          });

          componant.$root.$emit('change_cancel_nft',nft_id);

          componant.$root.$emit('loading_action_on_nft',{'nft_id': nft_id,'loading' : false});

        }, 2500);

        return true;

      }

    }

	},
}
</script>

