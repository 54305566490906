<template>
	<div>
		
	
		<footer id="footer" class="footer-light-style clearfix">
			<div class="themesflat-container">
				<div class="row">
					<div class="col-lg-3 col-md-12 col-12">
						<div class="widget widget-logo">
							<div class="logo-footer" id="logo-footer">
								<a href="index.html">
									<img id="logo_footer" src="/assets/images/logo/logo_dark.png" alt="nft-gaming" width="135" height="56"
									data-retina="/assets/images/logo/logo_dark@2x.png" data-width="135"
									data-height="56" class="bd-dark">
									<img id="logo_footer" src="/assets/images/logo/logo_light.png" alt="nft-gaming" width="135" height="56"
									data-retina="/assets/images/logo/logo_light@2x.png" data-width="135"
									data-height="56" class="bd-light">
								</a>
							</div>
							<p class="sub-widget-logo">BlueDiamonds is defining a new standard as the most complete Solana NFTs Marketplace. Buying and selling NFTs has never been that easy, enjoyable and profitable.</p>
						</div>
						<div class="bd-collectionpage-social-link widget-social style-1">
							<ul class="social-footer">
								<li><a href="https://twitter.com/BlueDiamondsNFT" target="_blank"><i class="fab fa-twitter"></i></a></li>
								<li><a href="https://marketplace.bluediamonds.app/" target="_blank"><i class="fas fa-globe"></i></a></li>
								<li><a href="https://discord.gg/trJCkzd8FR" target="_blank"><i class="icon-fl-vt"></i></a></li>
							</ul>
						</div>
					</div>
					<div class="col-lg-3 col-md-4 col-sm-5 col-5">
						<div class="widget widget-menu style-1">
							<h5 class="title-widget">Market Place</h5>
							<ul>
								<li>
									<router-link :to="'/'">
										Home
									</router-link>
								</li>
								<li>
									<router-link :to="'/all-collections'">
										All Collections
									</router-link>
								</li>
								<li>
									<router-link :to="'/all-nfts'">
										All NFTs
									</router-link>
								</li>
								<li>
									<router-link :to="'/ranking'">
										Ranking
									</router-link>
								</li>
								<li>
									<router-link :to="'/activities'">
										Activities
									</router-link>
								</li>
								<li>
									<router-link :to="'/faq'">
										Help / FAQ
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-3 col-md-4 col-sm-7 col-7 bd-desktop">
						<div class="widget widget-menu style-2">
							<h5 class="title-widget">My Account</h5>
							<ul>
								<li>
									<a v-if="$root.connected != 1" @click="$root.$emit('show_modal',['connect_wallet',true])" >
										Notifications
									</a>
									<router-link v-else :to="'/user/'+$root.wallet_address+'/notifications'">
										Notifications
									</router-link>
								</li>
								<li>
									<a v-if="$root.connected != 1" @click="$root.$emit('show_modal',['connect_wallet',true])">
										My NFTs
									</a>
									<router-link v-else :to="'/user/'+$root.wallet_address+'/my_nft'">
										My NFTs
									</router-link>
								</li>
								<li>
									<a v-if="$root.connected != 1" @click="$root.$emit('show_modal',['connect_wallet',true])">
										My Offers
									</a>
									<router-link v-else :to="'/user/'+$root.wallet_address+'/my_offers'">
										My Offers
									</router-link>
								</li>
								<li>
									<a v-if="$root.connected != 1" @click="$root.$emit('show_modal',['connect_wallet',true])">
										My Favorites
									</a>
									<router-link v-else :to="'/user/'+$root.wallet_address+'/favorites'">
										My Favorites
									</router-link>
								</li>
								<li>
									<a v-if="$root.connected != 1" @click="$root.$emit('show_modal',['connect_wallet',true])">
										My Activities
									</a>
									<router-link v-else :to="'/user/'+$root.wallet_address+'/activities'">
										My Activities
									</router-link>
								</li>
								<li>
									<a v-if="$root.connected != 1" @click="$root.$emit('show_modal',['connect_wallet',true])">
										Connect My Wallet
									</a>
									<router-link v-else :to="'/user/'+$root.wallet_address">
										My Wallet
									</router-link>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-7 col-12">
						<div class="widget widget-subcribe">
							<h5 class="title-widget">Stay In The<br>Loop</h5>
							<p class="sub-widget-logo">
								Join our mailing list to stay in the loop with our newest feature
								releases, NFT drops, and tips and tricks for navigating NFTs.
							</p>
							<div class="">
								
								<form class="form-footer" :action="route_server+'/newsletter'" method="post" autocomplete="off">
									<div class="bd-search-box-block">
										<input class="email_input_footer" type="email" name="email" id="bd-newletter" placeholder="Enter your email address.." required="required" autocomplete="false">
										<button id="button_mail_newsletter" style="background: var(--primary);padding:5px;" type="submit">
											Subscribe
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<div class="container-copyright">
			<div class="row" style="width: 100%;margin: 0">
				<div class="col-12" style="text-align: center;width: 100%;margin: 0;padding: 0;">
					<hr class="hr-footer">
					<p>Copyright © 2022 Blue Diamonds</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    
  },
  data: function () {
	
		return {

			route_server: process.env.VUE_APP_ROOT_API,
		}
	},
  created: function() {
		
  },
}
</script>

