import {
  Account,
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
  SystemProgram,
  TransactionInstruction,
  Transaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js';

import BN from "bn.js";

import * as anchor from '@project-serum/anchor';
import Wallet from '@project-serum/sol-wallet-adapter';

import { establishConnection, getTokenAccountForMintAddress, fetchAccount, fetchAccountOffer} from "@/libs/solanaConnection";

import { TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";

import _idl from "@/IDL-v5.json";

import { Metadata } from "@metaplex-foundation/mpl-token-metadata";

const idl = _idl;

const VAULT_ACCOUNT_PREFIX = "ac_vault";
const VAULT_AUTHORITY_PREFIX = "au_vault";
const VAULT_ACCOUNT_OFFER_PREFIX = "aco_vault";
const VAULT_AUTHORITY_OFFER_PREFIX = "auo_vault";

// Address of the deployed program.
// const program_id = new PublicKey('GhUxJVXuvy6vk9wAU617x2QFLFwThF5q51hTsbbtXjgY');
const program_id = new PublicKey('72ZHH2aZji37xvSQco8oTLQzXYYAQoZXLskvQYNiLVPz');
// const program_id = new PublicKey('4GCdgLVhFTY6KHdKmMqVBSsmxq4RJnjtKyPLBJDKEVc5');

let connection: Connection;

import axios from 'axios';
import {getOwnersFromTokenAccounts} from "./solanaConnection";
import bs58 from "bs58";

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

export async function apiCall(url, datas) {
	
	return new Promise(resolve => {
		
		var resultApiCall = null;
		
		axios.post(process.env.VUE_APP_ROOT_API+url, datas).then(function (result) {
			
			return resolve(result.data.result);
		});
		
	});
}

export async function saveSignature(datas) {
	
	return new Promise(resolve => {
		
		axios.post(process.env.VUE_APP_ROOT_API+'/maintenance/save_transaction', datas).then(function (result) {
			
			return resolve(result.data.transaction_id);
		});
		
	});
}

export async function decode_escrow(escrow) {
	
	var program = null;

	connection = await establishConnection();

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, window.solana, options);
	anchor.setProvider(provider);
	program = new anchor.Program(idl, program_id, provider);
	
	var escrowAccount = await fetchAccount(escrow, program);
	
	console.log(escrowAccount);
	console.log(escrowAccount.price.toString() / 1000000000);
	
}


export async function list_nft_program(wallet_provider, price, nft_mint_address) {

	if(isNaN(price))
		return 'You need to put the price of the NFT !';

	var price_in_lamports = price * 1000000000;

	var program = null;

	connection = await establishConnection();

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	program = new anchor.Program(idl, program_id, provider);

	var token_account = await getTokenAccountForMintAddress(nft_mint_address);

	if(token_account == null){

		return 'Error while listing your NFT, please refresh and try again';
	}

	var nft_mint_address_format = new PublicKey(nft_mint_address);

	var number_random_for_seeds = Math.floor(Math.random() * 256);

	var escrowAccount = null;

	const [_vault_account_pda, _vault_account_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_ACCOUNT_PREFIX),[number_random_for_seeds], nft_mint_address_format.toBuffer(), wallet_provider.publicKey.toBuffer()],
		program_id
	);

	var vault_account_pda = _vault_account_pda;
	var vault_account_bump = _vault_account_bump;

	const [_vault_authority_pda, _vault_authority_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_AUTHORITY_PREFIX),[number_random_for_seeds], nft_mint_address_format.toBuffer(), wallet_provider.publicKey.toBuffer()],
		program_id
	);

	var vault_authority_pda = _vault_authority_pda;

	// on crée l'escrow account
	escrowAccount = anchor.web3.Keypair.generate();

	const transaction = await program.transaction.list(
		vault_account_bump,
		new anchor.BN(price_in_lamports),
		number_random_for_seeds,
		{
			accounts: {
				initializer: wallet_provider.publicKey,
				vaultAccount: vault_account_pda,
				mint: nft_mint_address_format,
				initializerNftTokenAccount: token_account,
				escrowAccount: escrowAccount.publicKey,
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			instructions: [
				await program.account.escrowAccount.createInstruction(escrowAccount),
			],
			signers: [escrowAccount, wallet_provider],
		}
	);

	transaction.feePayer = wallet_provider.publicKey;
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;

	transaction.sign(escrowAccount);

	var signature = null;

	if(wallet_provider.type_wallet !== undefined && wallet_provider.type_wallet === 'slope') {

		const {msg, data} = await wallet_provider.signTransaction(
			bs58.encode(transaction.serializeMessage())
		)

		if (msg === 'ok') {
			transaction.addSignature(
				new PublicKey(data.publicKey),
				bs58.decode(data.signature)
			);

			signature = await connection.sendRawTransaction(transaction.serialize());
		}
	}
	else {
		var signedTransaction = await wallet_provider.signTransaction(transaction);

		signature = await connection.sendRawTransaction(signedTransaction.serialize());

	}
	
	// @todo enregistrer la signature ou qqch au cas ou la connexion timeout mais que la transaction passe quand même ?
	var datas = {

		mint_address: nft_mint_address,
		price: price,
		signature: signature,
		owner: wallet_provider.publicKey.toString(),
		escrow_account: escrowAccount.publicKey.toString(),
	};
	
	var datas_signature = {
		
		type: 30,
		signature: signature,
		data: datas,
	};
	
	var transaction_id = await saveSignature(datas_signature);
	
	datas.transaction_id = transaction_id;
	
	var result = await apiCall('/marketplace/nft/list', datas);

	return result;
}

export async function cancel_list_program(wallet_provider, nft) {

	var program = null;

	connection = await establishConnection();

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	program = new anchor.Program(idl, program_id, provider);

	var nft_mint_address = nft.mint_address;
	var nft_mint_address_format = new PublicKey(nft_mint_address);

	if(nft.escrow_account == null){

		return "This NFT is already unlisted";
	}

	var escrowAccount = await fetchAccount(nft.escrow_account, program);

	var escrowAccountKey = nft.escrow_account;
	var number_random_for_seeds = escrowAccount.numberRandomForSeeds;

	const [_vault_account_pda, _vault_account_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_ACCOUNT_PREFIX),[number_random_for_seeds], nft_mint_address_format.toBuffer(), wallet_provider.publicKey.toBuffer()],
		program_id
	);

	var vault_account_pda = _vault_account_pda;
	var vault_account_bump = _vault_account_bump;

	const [_vault_authority_pda, _vault_authority_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_AUTHORITY_PREFIX),[number_random_for_seeds], nft_mint_address_format.toBuffer(), wallet_provider.publicKey.toBuffer()],
		program_id
	);

	var vault_authority_pda = _vault_authority_pda;

	const transaction = await program.transaction.cancel({
		accounts: {
			initializer: wallet_provider.publicKey,
			vaultAccount: vault_account_pda,
			vaultAuthority: vault_authority_pda,
			initializerNftTokenAccount: new PublicKey(escrowAccount.initializerNftTokenAccount),
			mint: nft_mint_address_format,
			escrowAccount: new PublicKey(escrowAccountKey),
			tokenProgram: TOKEN_PROGRAM_ID,
			systemProgram: anchor.web3.SystemProgram.programId,
		},
		signers: [wallet_provider]
	});

	transaction.feePayer = wallet_provider.publicKey;
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;

	var signature = null;

	if(wallet_provider.type_wallet !== undefined && wallet_provider.type_wallet === 'slope') {

		const {msg, data} = await wallet_provider.signTransaction(
			bs58.encode(transaction.serializeMessage())
		)

		if (msg === 'ok') {
			transaction.addSignature(
				new PublicKey(data.publicKey),
				bs58.decode(data.signature)
			);

			signature = await connection.sendRawTransaction(transaction.serialize());
		}
	}
	else {
		var signedTransaction = await wallet_provider.signTransaction(transaction);

		signature = await connection.sendRawTransaction(signedTransaction.serialize());

	}

	// @todo enregistrer la signature ou qqch au cas ou la connexion timeout mais que la transaction passe quand même ?

	// save data in the DB
	var datas = {

		mint_address: nft_mint_address,
		signature: signature,
	};
	
	var datas_signature = {
		
		type: 32,
		signature: signature,
		data: datas,
	};
	
	var transaction_id = await saveSignature(datas_signature);
	
	datas.transaction_id = transaction_id;

	var result = await apiCall('/marketplace/nft/unlist', datas);

	return result;
}

export async function buy_nft_program(wallet_provider, price, mint_address, escrow_account) {

	connection = await establishConnection();

	var tokenmeta = await (async () => {
		let mintPubkey = new PublicKey(mint_address);
		let tokenmetaPubkey = await Metadata.getPDA(mintPubkey);

		const tokenmeta = await Metadata.load(connection, tokenmetaPubkey);

		return tokenmeta;
	})();

	var seller_fee_basis_points = tokenmeta.data.data.sellerFeeBasisPoints;

	var creator_address_0 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_0 = 0;
	var creator_address_1 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_1 = 0;
	var creator_address_2 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_2 = 0;
	var creator_address_3 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_3 = 0;
	var creator_address_4 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_4 = 0;
	var creator_address_5 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_5 = 0;
	var creator_address_6 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_6 = 0;
	var creator_address_7 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_7 = 0;
	var creator_address_8 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_8 = 0;
	var creator_address_9 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_9 = 0;

	var count = 0;

	Object.values(tokenmeta.data.data.creators).forEach(creator => {
		if(creator.share > 0){
			if(count === 0) {
				creator_address_0 = creator.address;
				creator_share_0 = creator.share;
			}
			else if(count === 1) {
				creator_address_1 = creator.address;
				creator_share_1 = creator.share;
			}
			else if(count === 2) {
				creator_address_2 = creator.address;
				creator_share_2 = creator.share;
			}
			else if(count === 3) {
				creator_address_3 = creator.address;
				creator_share_3 = creator.share;
			}
			else if(count === 4) {
				creator_address_4 = creator.address;
				creator_share_4 = creator.share;
			}
			else if(count === 5) {
				creator_address_5 = creator.address;
				creator_share_5 = creator.share;
			}
			else if(count === 6) {
				creator_address_6 = creator.address;
				creator_share_6 = creator.share;
			}
			else if(count === 7) {
				creator_address_7 = creator.address;
				creator_share_7 = creator.share;
			}
			else if(count === 8) {
				creator_address_8 = creator.address;
				creator_share_8 = creator.share;
			}
			else if(count === 9) {
				creator_address_9 = creator.address;
				creator_share_9 = creator.share;
			}

			count ++;
		}
	});

	var mint = new PublicKey(mint_address);
	var buyer = wallet_provider;
	
	var system_program = new PublicKey('11111111111111111111111111111111');
	
	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	const program = new anchor.Program(idl, program_id, provider);

	var escrowAccountKey = new PublicKey(escrow_account);

	var escrowAccount = await fetchAccount(escrow_account, program);

	var seller = new PublicKey(escrowAccount.initializerKey);

	var number_random_for_seeds = escrowAccount.numberRandomForSeeds;

	// check if the buyer as a token account for this nft
	// var tokenAccountForBuyer = await findAssociatedTokenAddress(buyer.publicKey, mint);

	// we create the tokenAccount
	var myToken = new Token(
		connection,
		mint,
		TOKEN_PROGRAM_ID,
		buyer.publicKey // the wallet owner will pay to transfer and to create recipients associated token account if it does not yet exist.
	);
	
	const associatedDestinationTokenAddr = await Token.getAssociatedTokenAddress(
		myToken.associatedProgramId,
		myToken.programId,
		mint,
		buyer.publicKey
	);

	const receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr);
	
	// => c'est le cas où il faut créer le associated token account pour l'acheteur... il faudrait probablement l'inclure dans la transaction ci dessous si nécessaire
	// car sinon ça fait faire 2 transactions...
	// @todo l'intégrer à la transaction de la vente
	// le plus simple est peut être de tester d'envoyer directement le nft au main wallet de l'acheteur, car via phantom c'est automatique... alors peut être que....?
	const [_vault_account_pda, _vault_account_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_ACCOUNT_PREFIX),[number_random_for_seeds], mint.toBuffer(), seller.toBuffer()],
		program_id
	);

	var vault_account_pda = _vault_account_pda;
	var vault_account_bump = _vault_account_bump;

	const [_vault_authority_pda, _vault_authority_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_AUTHORITY_PREFIX),[number_random_for_seeds], mint.toBuffer(), seller.toBuffer()],
		program_id
	);

	var vault_authority_pda = _vault_authority_pda;
	
	// const transaction = await program.transaction.initialize(new BN(price_in_lamports), {        
	const transaction_anchor = {
		accounts: {
			buyer: buyer.publicKey,
			seller: seller,
			systemProgram: anchor.web3.SystemProgram.programId,
			takerReceiveTokenAccount: associatedDestinationTokenAddr,
			escrowAccount: escrowAccountKey,
			vaultAccount: vault_account_pda,
			vaultAuthority: vault_authority_pda,
			mint: mint,
			transactionFees1: new PublicKey('2wazHv3T8PD8tUGZq1V4AUEJcRhThXZ8YJGW55oHejpr'),
			transactionFees2: new PublicKey('E2bG3prvWShYHDoNnxuRGdDj3ch3SVNpfodvA6tewKJ'),
			creatorAddress0: new PublicKey(creator_address_0),
			creatorAddress1: new PublicKey(creator_address_1),
			creatorAddress2: new PublicKey(creator_address_2),
			creatorAddress3: new PublicKey(creator_address_3),
			creatorAddress4: new PublicKey(creator_address_4),
			/*creatorAddress5: new PublicKey(creator_address_5),
			creatorAddress6: new PublicKey(creator_address_6),
			creatorAddress7: new PublicKey(creator_address_7),
			creatorAddress8: new PublicKey(creator_address_8),
			creatorAddress9: new PublicKey(creator_address_9),*/
			tokenProgram: TOKEN_PROGRAM_ID,
		},
		signers: [buyer]
	};

	if(receiverAccount === null) {

		if (receiverAccount === null) {

			var instruction = Token.createAssociatedTokenAccountInstruction(
				myToken.associatedProgramId,
				myToken.programId,
				mint,
				associatedDestinationTokenAddr,
				buyer.publicKey,
				buyer.publicKey
			)

			transaction_anchor.instructions = [instruction];

		}
	}

	const transaction = await program.transaction.initialize(new anchor.BN(seller_fee_basis_points),
		new anchor.BN(creator_share_0),
		new anchor.BN(creator_share_1),
		new anchor.BN(creator_share_2),
		new anchor.BN(creator_share_3),
		new anchor.BN(creator_share_4),
		/*new anchor.BN(creator_share_5),
		new anchor.BN(creator_share_6),
		new anchor.BN(creator_share_7),
		new anchor.BN(creator_share_8),
		new anchor.BN(creator_share_9),*/
		transaction_anchor,
	);

	transaction.feePayer = buyer.publicKey;
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;

	var signature = null;

	if(wallet_provider.type_wallet !== undefined && wallet_provider.type_wallet === 'slope') {

		const {msg, data} = await wallet_provider.signTransaction(
			bs58.encode(transaction.serializeMessage())
		)

		if (msg === 'ok') {
			transaction.addSignature(
				new PublicKey(data.publicKey),
				bs58.decode(data.signature)
			);

			signature = await connection.sendRawTransaction(transaction.serialize());
		}
	}
	else {
		var signedTransaction = await wallet_provider.signTransaction(transaction);

		signature = await connection.sendRawTransaction(signedTransaction.serialize());

	}
	
	// save data in the DB
	var datas = {
		
		mint_address: mint_address,
		buyer_wallet: buyer.publicKey.toString(),
		signature: signature,
	};
	
	var datas_signature = {
		
		type: 31,
		signature: signature,
		data: datas,
	};
	
	var transaction_id = await saveSignature(datas_signature);
	
	datas.transaction_id = transaction_id;

	var result = await apiCall('/marketplace/nft/buy', datas);

	return result;
	
}

export async function make_offer_program(wallet_offerer, price, nft_mint_address) {

	if(isNaN(price))
		return 'You need to put the price of the offer !';

	var price_in_lamports = price * 1000000000;

	connection = await establishConnection();

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_offerer, options);
	anchor.setProvider(provider);
	var program = new anchor.Program(idl, program_id, provider);

	var nft_mint_address_format = new PublicKey(nft_mint_address);

	var number_random_for_seeds_offer = Math.floor(Math.random() * 256);

	const [_vault_account_pda_offer, _vault_account_bump_offer] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_ACCOUNT_OFFER_PREFIX), [number_random_for_seeds_offer], nft_mint_address_format.toBuffer(), wallet_offerer.publicKey.toBuffer()],
		program_id
	);

	var vault_account_pda_offer = _vault_account_pda_offer;
	var vault_account_bump_offer = _vault_account_bump_offer;

	// on crée l'escrow account
	var escrowAccountOffer = anchor.web3.Keypair.generate();

	const transaction = await program.transaction.makeOffer(
		vault_account_bump_offer,
		new anchor.BN(price_in_lamports),
		number_random_for_seeds_offer,
		{
			accounts: {
				offerer: wallet_offerer.publicKey,
				mint: nft_mint_address_format,
				systemProgram: anchor.web3.SystemProgram.programId,
				escrowAccountOffer: escrowAccountOffer.publicKey,
				vaultAccount: vault_account_pda_offer,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			instructions: [
				await program.account.escrowAccountOffer.createInstruction(escrowAccountOffer),
			],
			signers: [escrowAccountOffer, wallet_offerer]
		});

	transaction.feePayer = wallet_offerer.publicKey;
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;

	transaction.sign(escrowAccountOffer);

	var signature = null;

	if(wallet_offerer.type_wallet !== undefined && wallet_offerer.type_wallet === 'slope') {

		const {msg, data} = await wallet_offerer.signTransaction(
			bs58.encode(transaction.serializeMessage())
		)

		if (msg === 'ok') {
			transaction.addSignature(
				new PublicKey(data.publicKey),
				bs58.decode(data.signature)
			);

			signature = await connection.sendRawTransaction(transaction.serialize());
		}
	}
	else {
		var signedTransaction = await wallet_offerer.signTransaction(transaction);

		signature = await connection.sendRawTransaction(signedTransaction.serialize());

	}

	console.log('signature', signature);

	// save data in the DB
	var datas = {

		mint_address: nft_mint_address,
		price: price,
		signature: signature,
		offerer: wallet_offerer.publicKey.toString(),
		escrow_account: escrowAccountOffer.publicKey.toString(),
	};
	
	var datas_signature = {
		
		type: 33,
		signature: signature,
		data: datas,
	};
	
	var transaction_id = await saveSignature(datas_signature);
	
	datas.transaction_id = transaction_id;

	var result = await apiCall('/marketplace/nft/make_offer', datas);

	return result;
}

export async function cancel_make_offer_program(wallet_offerer, offer) {

	connection = await establishConnection();

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_offerer, options);
	anchor.setProvider(provider);
	var program = new anchor.Program(idl, program_id, provider);

	var nft_mint_address = offer.mint_address;
	var nft_mint_address_format = new PublicKey(nft_mint_address);

	if(offer.escrow_account == null){

		return "This offer have been already canceled";
	}

	var escrowAccountOfferKey = new PublicKey(offer.escrow_account);

	var escrowAccountOffer = await fetchAccountOffer(offer.escrow_account, program);

	var number_random_for_seeds_offer = escrowAccountOffer.numberRandomForSeeds;

	const [_vault_account_pda_offer, _vault_account_bump_offer] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_ACCOUNT_OFFER_PREFIX), [number_random_for_seeds_offer], nft_mint_address_format.toBuffer(), wallet_offerer.publicKey.toBuffer()],
		program_id
	);

	var vault_account_pda_offer = _vault_account_pda_offer;

	const transaction = await program.transaction.cancelOffer({
		accounts: {
			offerer: wallet_offerer.publicKey,
			vaultAccountOffer: vault_account_pda_offer,
			escrowAccountOffer: escrowAccountOfferKey,
			systemProgram: anchor.web3.SystemProgram.programId,
		},
		signers: [wallet_offerer]
	});

	transaction.feePayer = wallet_offerer.publicKey;
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;

	var signature = null;

	if(wallet_offerer.type_wallet !== undefined && wallet_offerer.type_wallet === 'slope') {

		const {msg, data} = await wallet_offerer.signTransaction(
			bs58.encode(transaction.serializeMessage())
		)

		if (msg === 'ok') {
			transaction.addSignature(
				new PublicKey(data.publicKey),
				bs58.decode(data.signature)
			);

			signature = await connection.sendRawTransaction(transaction.serialize());
		}
	}
	else {
		var signedTransaction = await wallet_offerer.signTransaction(transaction);

		signature = await connection.sendRawTransaction(signedTransaction.serialize());

	}

	console.log('signature', signature);

	// @todo enregistrer la signature ou qqch au cas ou la connexion timeout mais que la transaction passe quand même ?

	var datas = {

		offer_id: offer.id,
		signature: signature,
	};
	
	var datas_signature = {
		
		type: 34,
		signature: signature,
		data: datas,
	};
	
	var transaction_id = await saveSignature(datas_signature);
	
	datas.transaction_id = transaction_id;

	var result = await apiCall('/marketplace/nft/cancel_offer', datas);

	return result;
}

export async function accept_offer_program(wallet_provider, offer, escrow_account) {
	
	connection = await establishConnection();
	
	var tokenmeta = await (async () => {
		let mintPubkey = new PublicKey(offer.mint_address);
		let tokenmetaPubkey = await Metadata.getPDA(mintPubkey);

		const tokenmeta = await Metadata.load(connection, tokenmetaPubkey);

		return tokenmeta;
	})();

	var seller_fee_basis_points = tokenmeta.data.data.sellerFeeBasisPoints;

	var creator_address_0 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_0 = 0;
	var creator_address_1 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_1 = 0;
	var creator_address_2 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_2 = 0;
	var creator_address_3 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_3 = 0;
	var creator_address_4 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_4 = 0;
	var creator_address_5 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_5 = 0;
	var creator_address_6 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_6 = 0;
	var creator_address_7 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_7 = 0;
	var creator_address_8 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_8 = 0;
	var creator_address_9 = 'DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac';
	var creator_share_9 = 0;

	var count = 0;

	Object.values(tokenmeta.data.data.creators).forEach(creator => {
		if(creator.share > 0){
			if(count === 0) {
				creator_address_0 = creator.address;
				creator_share_0 = creator.share;
			}
			else if(count === 1) {
				creator_address_1 = creator.address;
				creator_share_1 = creator.share;
			}
			else if(count === 2) {
				creator_address_2 = creator.address;
				creator_share_2 = creator.share;
			}
			else if(count === 3) {
				creator_address_3 = creator.address;
				creator_share_3 = creator.share;
			}
			else if(count === 4) {
				creator_address_4 = creator.address;
				creator_share_4 = creator.share;
			}
			else if(count === 5) {
				creator_address_5 = creator.address;
				creator_share_5 = creator.share;
			}
			else if(count === 6) {
				creator_address_6 = creator.address;
				creator_share_6 = creator.share;
			}
			else if(count === 7) {
				creator_address_7 = creator.address;
				creator_share_7 = creator.share;
			}
			else if(count === 8) {
				creator_address_8 = creator.address;
				creator_share_8 = creator.share;
			}
			else if(count === 9) {
				creator_address_9 = creator.address;
				creator_share_9 = creator.share;
			}

			count ++;
		}
	});

	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);
	anchor.setProvider(provider);
	var program = new anchor.Program(idl, program_id, provider);

	var nft_mint_address = offer.mint_address;
	var nft_mint_address_format = new PublicKey(nft_mint_address);

	var myToken = new Token(
		connection,
		nft_mint_address,
		TOKEN_PROGRAM_ID,
		wallet_provider.publicKey // the wallet owner will pay to transfer and to create recipients associated token account if it does not yet exist.
	);

	var escrowAccountKey = new PublicKey(escrow_account);

	var escrowAccount = await fetchAccount(escrow_account, program);

	var number_random_for_seeds = escrowAccount.numberRandomForSeeds;

	const [_vault_account_pda, _vault_account_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_ACCOUNT_PREFIX),[number_random_for_seeds], nft_mint_address_format.toBuffer(), wallet_provider.publicKey.toBuffer()],
		program_id
	);

	var vault_account_pda = _vault_account_pda;
	var vault_account_bump = _vault_account_bump;

	const [_vault_authority_pda, _vault_authority_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_AUTHORITY_PREFIX),[number_random_for_seeds], nft_mint_address_format.toBuffer(), wallet_provider.publicKey.toBuffer()],
		program_id
	);

	var vault_authority_pda = _vault_authority_pda;

	if(offer.escrow_account == null){

		return "This offer doesn't exist";
	}

	var escrowAccountOfferKey = new PublicKey(offer.escrow_account);

	var escrowAccountOffer = await fetchAccountOffer(offer.escrow_account, program);

	var buyer = new PublicKey(escrowAccountOffer.offerer);
	

	var number_random_for_seeds_offer = escrowAccountOffer.numberRandomForSeeds;

	const [_vault_account_pda_offer, _vault_account_bump_offer] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_ACCOUNT_OFFER_PREFIX), [number_random_for_seeds_offer], nft_mint_address_format.toBuffer(), buyer.toBuffer()],
		program_id
	);

	var vault_account_pda_offer = _vault_account_pda_offer;
	
	const associatedDestinationTokenAddr = await Token.getAssociatedTokenAddress(
		myToken.associatedProgramId,
		myToken.programId,
		nft_mint_address_format,
		buyer,
	);
	
	var transaction_anchor = {
		accounts: {
			seller: wallet_provider.publicKey,
			buyer: buyer,
			mint: nft_mint_address_format,
			vaultAccount: vault_account_pda,
			vaultAuthority: vault_authority_pda,
			vaultAccountOffer: vault_account_pda_offer,
			escrowAccount: escrowAccountKey,
			escrowAccountOffer: escrowAccountOfferKey,
			takerReceiveTokenAccount: associatedDestinationTokenAddr,
			transactionFees1: new PublicKey('2wazHv3T8PD8tUGZq1V4AUEJcRhThXZ8YJGW55oHejpr'),
			transactionFees2: new PublicKey('E2bG3prvWShYHDoNnxuRGdDj3ch3SVNpfodvA6tewKJ'),
			creatorAddress0: new PublicKey(creator_address_0),
			creatorAddress1: new PublicKey(creator_address_1),
			creatorAddress2: new PublicKey(creator_address_2),
			creatorAddress3: new PublicKey(creator_address_3),
			/*creatorAddress4: new PublicKey(creator_address_4),
			creatorAddress5: new PublicKey(creator_address_5),
			creatorAddress6: new PublicKey(creator_address_6),
			creatorAddress7: new PublicKey(creator_address_7),
			creatorAddress8: new PublicKey(creator_address_8),
			creatorAddress9: new PublicKey(creator_address_9),*/
			systemProgram: anchor.web3.SystemProgram.programId,
			tokenProgram: TOKEN_PROGRAM_ID,
			rent: anchor.web3.SYSVAR_RENT_PUBKEY

		},
		signers: [wallet_provider],
	};
	
	const receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr);
	
	if(receiverAccount === null) {

		var instruction = Token.createAssociatedTokenAccountInstruction(
			myToken.associatedProgramId,
			myToken.programId,
			nft_mint_address_format,
			associatedDestinationTokenAddr,
			buyer,
			wallet_provider.publicKey
		);

		transaction_anchor.instructions = [instruction];
	}
	
	try {
		const transaction = await program.transaction.acceptOffer(new anchor.BN(seller_fee_basis_points),
		new anchor.BN(creator_share_0),
		new anchor.BN(creator_share_1),
		new anchor.BN(creator_share_2),
		new anchor.BN(creator_share_3),
		new anchor.BN(creator_share_4),
		transaction_anchor);
		
		transaction.feePayer = wallet_provider.publicKey;
		transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
		var signedTransaction = await wallet_provider.signTransaction(transaction);
		var signature = await connection.sendRawTransaction(signedTransaction.serialize());

	
	}
	catch(e) {
		console.log(e);
	}
	
	var datas = {

		offer_id: offer.id,
		signature: signature,
	};
	
	var datas_signature = {
		
		type: 35,
		signature: signature,
		data: datas,
	};
	
	var transaction_id = await saveSignature(datas_signature);
	
	datas.transaction_id = transaction_id;

	var result = await apiCall('/marketplace/nft/accept_offer', datas);

	return result;
}
