<template>
  <Modal :show_modal="show_modal" name_modal="cancel_make_offer" style_modal="height:30%;width:300px;min-height:300px">
    <template v-slot:title>Cancel offer</template>
    <template v-slot:body>
      <p>Are you sure you want to cancel your offer ?</p>
    </template>
    <template v-slot:footer>
      <button @click="cancel_make_offer">I'm sure !</button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/Modal.vue';
import {cancel_make_offer_program} from "../../libs/rustProgram";
import {getWalletAddress} from '@/libs/Wallet.js';
import {getSolanaObject} from "../../libs/Wallet";

export default {
  name: "ModalCancelMakeOffer",
  components: {
		Modal
	},
  props: {
    show_modal:Boolean,
    offer:{},
	},
  methods:{

    cancel_make_offer : function(){

        var componant = this;

        componant.error_alert = '';

        if(componant.$root.transaction_in_progress) {

           componant.error_alert = 'A transaction is already in progress !';

           return false;

        }

        componant.$root.loading(true);

        if(getWalletAddress() === false)
          componant.$root.$emit('show_modal',['connect_wallet',true]);

        else
          componant.cancel_make_offer_call_program();

      },

    async cancel_make_offer_call_program(){

      var componant = this;

      componant.$root.transaction_in_progress = true;

      var response = true;

      response = await cancel_make_offer_program(getSolanaObject(), componant.offer).catch(
        (error) => {
          response = "Error while confirming transaction, please refresh and try again !";
        }
      );

      componant.$root.transaction_in_progress = false;

      if(response !== true){

        componant.$root.loading(false);

        componant.error_alert = response;

        componant.$dtoast.pop({
          preset: "error",
          heading: `Error`,
          content:  `The cancel of your offer can't be proceed, please try again !`,
        });
      }

      else {

        setTimeout(function () {

          componant.$dtoast.pop({
            preset: "success",
            heading: `Success`,
            content:  `Your offer is canceled !`,
          });

          componant.$root.$emit('show_modal', ['cancel_make_offer', false]);

          componant.$root.$emit('change_cancel_make_offer');

          componant.$root.loading(false);
        }, 2500);

      }

    },
  },
  watch: {
    'nft.id': function () {
      this.price = '';
    }
  },
}
</script>