<template>
  <Modal :show_modal="show_modal" name_modal="make_offer" style_modal="height:30%;width:300px;min-height:300px">
    <template v-slot:title>Make an offer</template>
    <template v-slot:body>
      <input class="form-control" type="number" v-model="price"/>
      <h6 style="margin-top: 5%;color:#dd2d2d;" v-html="alert_floor_price_offer"></h6>
    </template>
    <template v-slot:footer v-if="alert_floor_price_offer === ''">
      <button @click="make_offer">Validate</button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/Modal.vue';
import {make_offer_program} from "../../libs/rustProgram";
import {getWalletAddress} from '@/libs/Wallet.js';
import {getSolanaObject} from "../../libs/Wallet";

export default {
  name: "ModalMakeOffer",
  components: {
		Modal
	},
  props: {
    show_modal:Boolean,
    floor_price: Number,
    nft:{},
	},
  data: function(){
    return {
      price: '',
    }
  },
  computed : {
    alert_floor_price_offer: function () {

      var price = parseFloat(this.price);

      if (!isNaN(price) && price < this.floor_price * 30 / 100)
        return "Offer should be at least 30% of collection's floor price";

      return '';
    },

  },
  methods:{

    make_offer : function(){

        var componant = this;

        componant.error_alert = '';

        if(componant.$root.transaction_in_progress) {

           componant.error_alert = 'A transaction is already in progress !';

           return false;

        }

        if(isNaN(componant.price)) {
          componant.error_alert = 'You need to put the price of the offer !';

          return false;
        }

        componant.$root.loading(true);

        if(getWalletAddress() === false)
          componant.$root.$emit('show_modal', ['connect_wallet', true]);

        else
          componant.make_offer_call_program();

      },

    async make_offer_call_program(){

      var componant = this;

      componant.$root.transaction_in_progress = true;

      var response = true;

      response = await make_offer_program(getSolanaObject(), componant.price, componant.nft.mint_address).catch(
        (error) => {
          response = "Error while confirming transaction, please refresh and try again !";
        }
      );

      componant.$root.transaction_in_progress = false;

      if(response !== true){

        componant.$root.loading(false);

        componant.error_alert = response;

      componant.$dtoast.pop({
        preset: "error",
        heading: `Error`,
        content:  `Your offer can't be proceed, please try again !`,
      });


      }

      else {

        setTimeout(function () {

          componant.$dtoast.pop({
            preset: "success",
            heading: `Success`,
            content:  `Your offer is registered !`,
          });

          componant.$root.$emit('show_modal', ['make_offer', false]);

          componant.$root.$emit('change_make_offer');

          componant.$root.loading(false);
        }, 2500);

      }

    },
  },
  watch: {
    'nft.id': function () {
      this.price = '';
    }
  },
}
</script>