import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCompositionAPI from '@vue/composition-api'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
Vue.use(VueCompositionAPI)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/all-nfts',
    name: 'NftsList',
    component: () => import('../views/NftsList.vue')
  },
  {
    path: '/all-collections',
    name: 'CollectionList',
    component: () => import('../views/CollectionList.vue')
  },
  {
    path: '/all-collections/:tag',
    name: 'CollectionListWithTag',
    component: () => import('../views/CollectionList.vue')
  },
  {
    path: '/collection-page/:id',
    name: 'Collection',
    component: () => import('../views/Collection.vue')
  },
  {
    path: '/collection-edit/:id',
    name: 'CollectionEdit',
    component: () => import('../views/CollectionEdit.vue')
  },
  {
    path: '/collection-edit-nft',
    name: 'CollectionEditNft',
    component: () => import('../views/CollectionEditNft.vue')
  },
  {
    path: '/user/:wallet',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user/:wallet/:tab',
    name: 'UserTab',
    component: () => import('../views/User.vue')
  },
  {
    path: '/nft/:mint_address',
    name: 'Nft',
    component: () => import('../views/Nft.vue')
  },
  /*
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue')
  },
  */
  {
    path: '/ranking',
    name: 'Ranking',
    component: () => import('../views/Ranking.vue')
  },
  {
    path: '/activities',
    name: 'Activities',
    component: () => import('../views/Activities.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/apply-for-listing',
    name: 'ApplyForListing',
    component: () => import('../views/ApplyforListing.vue')
  },
  {
    path: '/test-rust',
    name: 'TestRust',
    component: () => import('../views/TestRust.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
