import {clusterApiUrl, Connection, PublicKey} from "@solana/web3.js";
import Wallet from "@project-serum/sol-wallet-adapter";

var slope_wallet = null;

var sollet_wallet = null;

export function getWalletAddress(){

    if(window.solana && window.solana.publicKey) {
        if (window.solana.publicKey.toBase58() != undefined)
            return window.solana.publicKey.toBase58();
    }
	
	else if(window.solflare && window.solflare.publicKey) {
        if (window.solflare.publicKey.toString() != undefined)
            return window.solflare.publicKey.toString();
    }

    else if(sollet_wallet != null && sollet_wallet.connected) {
        if (sollet_wallet.publicKey.toString() != undefined)
            return sollet_wallet.publicKey.toString();
    }

    else if(slope_wallet != null && slope_wallet.publicKey) {
        if (slope_wallet.publicKey.toString() != undefined)
            return slope_wallet.publicKey.toString();
    }
	
    return false;

}

export function WalletLogout(){

    if(window.solana && window.solana.publicKey !== null)
        window.solana.disconnect();

    else if(window.solflare && window.solflare.publicKey !== null)
        window.solflare.disconnect();

    else if(sollet_wallet != null && sollet_wallet.connected)
        sollet_wallet.disconnect();

    else if(slope_wallet && slope_wallet.publicKey !== undefined) {
        slope_wallet.disconnect();
        delete slope_wallet.publicKey;
        delete slope_wallet.type_wallet;
    }
}

export async function connexion_with_slope(){
	
    try {

        slope_wallet = new window.Slope();

        const { msg, data } = await slope_wallet.connect();

        if (msg === 'ok'){

            slope_wallet.publicKey = new PublicKey(data.publicKey);
            slope_wallet.type_wallet = 'slope';
            return slope_wallet;
        }
        else
            return false;
    }
    catch (error) {
        return false;
    }
}

export async function connexion_with_sollet(){

    let connection = new Connection(clusterApiUrl('devnet'));
    let providerUrl = 'https://www.sollet.io';

    sollet_wallet = new Wallet(providerUrl);

    await sollet_wallet.connect();

    return sollet_wallet;
}

export function getSolanaObject(){
    if(window.solana && window.solana.publicKey)
        return window.solana;
    else if(window.solflare && window.solflare.publicKey)
        return window.solflare;
    else if(sollet_wallet != null && sollet_wallet.connected)
        return sollet_wallet;
    else if(slope_wallet != null && slope_wallet.publicKey)
        return slope_wallet;
}