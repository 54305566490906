<template>
  <div class="sc-btn-top mg-r-12" id="site-header">
  <a v-if="$root.connected != 1" @click="$root.$emit('show_modal',['connect_wallet',true])" class="sc-button header-slider style style-1 wallet fl-button pri-1" id="bd-connect-wallet">Connect wallet</a>
  <router-link v-else :to="'/user/'+this.$root.wallet_address" class="sc-button header-slider style style-1 fl-button pri-1"><span>{{ $root.wallet_address | reduce_text(4,4) }}
                  </span></router-link>

  </div>

</template>

<script>

export default {
  name: 'WalletConnect',
  components: {

  },
  props: {

  },
  created: function() {

    var component_vue = this;
    var wallet_address = component_vue.$cookie.get('wallet_address');

    if(wallet_address !== null){

      component_vue.$root.wallet_address = wallet_address;
      component_vue.$forceUpdate();

    }

  },
}

</script>
