<template>
	<header id="header_main" class="header_1 header_2 style2 style3 js-header">
		<div id="bd-search-mobile" class="bd-mobile" v-show="mobile_display_search === true">
			<div class="bd-search-mobile-close" @click="mobile_display_search = false">Close</div>
			<input v-model="search_input" @keyup="search_collection">
			<div>
				<div v-show="search_input !== ''">
					<span v-if="Object.keys(search_result).length === 0">
					Sorry, no collection match your search...
					</span>
					<router-link :to="'/collection-page/'+collection.symbol" :key="collection.id" v-for="collection in search_result" >
						<div class="bd-header-mobile-search-result" @click="search_input = ''; mobile_display_search = false;">
							<img :src="route_bo + '/storage/' + collection.logo" />
							{{ collection.name }}
						</div>
					</router-link>
				</div>
			</div>
		</div>
	
		<!-- mobile -->
		<div class="themesflat-container bd-mobile bd-header-navbar-mobile">
			<div class="row">
				<div class="col-6">
					<router-link :to="'/'">
						<img id="logo_header" src="/assets/images/logo/logo_dark.png" class="bd-dark" alt="nft-gaming" width="200" height="30" data-retina="/assets/images/logo/logo_dark@2x.png" data-width="133" data-height="56">
						<img id="logo_header" src="/assets/images/logo/logo_light.png" class="bd-light" alt="nft-gaming" width="200" height="30" data-retina="/assets/images/logo/logo_dark@2x.png" data-width="133" data-height="56">
					</router-link>
				</div>
				<div class="col-6">
					<div style="float: right;">
						<div class="bd-mobile-search-button" @click="mobile_display_search = true;"><span class="fas fa-search"></span></div>
						<div class="mobile-button"><span></span></div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- desktop -->
		<div class="themesflat-container bd-desktop">
			<div class="row">
				<div class="col-md-12">
					<div id="site-header-inner">
						<div class="wrap-box flex">
							<div>
								<div id="site-logo" class="clearfix">
									<div id="site-logo-inner">
										<router-link :to="'/'">
											<img id="logo_header" src="/assets/images/logo/logo_dark.png" class="bd-dark" alt="nft-gaming" width="200" height="30" data-retina="/assets/images/logo/logo_dark@2x.png" data-width="133" data-height="56">
											<img id="logo_header" src="/assets/images/logo/logo_light.png" class="bd-light" alt="nft-gaming" width="200" height="30" data-retina="/assets/images/logo/logo_dark@2x.png" data-width="133" data-height="56">
										</router-link>
									</div>
								</div>
								<div class="question-form">
									<form action="javascript:void(0);">
										<div class="bd-search-box-block">
											<input type="text" v-model="search_input" @keyup="search_collection">
											<a id="button_search_bar" style="background: var(--primary);padding:5px;border-radius: 50%;" href="#">
												<i class="fas fa-search"></i>
											</a>
										</div>
									</form>
									<br>
									<div class="bd-header-search-results" v-show="search_input !== ''">
										<span v-if="Object.keys(search_result).length === 0">
										Sorry, no collection match your search...
										</span>
										<router-link :to="'/collection-page/'+collection.symbol" :key="collection.id" v-for="collection in search_result">
											<div class="bd-header-search-result" style="display: table;" @click="search_input = ''">
												<img :src="route_bo + '/storage/' + collection.logo" />
												{{ collection.name }}
											</div>
										</router-link>
									</div>
								</div>
								<nav id="main-nav" class="main-nav">
									<ul id="menu-primary-menu" class="menu">
										<li class="menu-item menu-item-has-children">
											<a href="#">Explore</a>
											<ul class="sub-menu">
												<li class="menu-item">
													<router-link :to="'/all-collections'">Collections</router-link>
												</li>
												<li class="menu-item">
													<router-link :to="'/all-nfts'">NFTs</router-link>
												</li>
											</ul>
										</li>
										<li class="menu-item menu-item-has-children">
											<a href="#">Stats</a>
											<ul class="sub-menu">
												<li class="menu-item">
													<router-link to="/ranking">Rankings</router-link>
												</li>
												<li class="menu-item">
													<router-link to="/activities">Activities</router-link>
												</li>
											</ul>
										</li>
										<li class="menu-item  menu-item-has-children">
											<a href="#">BlueDiamonds</a>
											<ul class="sub-menu">
												<!-- <li class="menu-item"><a href="contact1.html">Open Data API</a></li> -->
												<li class="menu-item"><router-link :to="'/faq'">Help / FAQ</router-link></li>
												<li class="menu-item"><router-link :to="'/apply-for-listing'">Apply for listing</router-link></li>
												<li class="submenu-item"><a target="_blank" href="https://discord.gg/xsE7agFjtd"><i class="fab fa-discord"></i></a><a target="_blank" href="https://twitter.com/BlueDiamondsNFT"><i class="fab fa-twitter"></i></a></li>
											</ul>
										</li>
										<li class="menu-item bd-mobile">
											<span class="far fa-moon bd-moon-sun bd-moon bd-mobile-navbar-menu-icon" @click="toggle_dark_mode"></span>
											<span class="far fa-sun bd-moon-sun bd-sun bd-mobile-navbar-menu-icon" @click="toggle_dark_mode"></span>
											<a href="#">Night mood </a>
										</li>
									</ul>
								</nav>
							</div>
							<!-- /#main-nav -->   
							<div class="flat-search-btn flex">
								<span class="far fa-moon bd-moon-sun bd-moon" @click="toggle_dark_mode"></span>
								<span class="far fa-sun bd-moon-sun bd-sun" @click="toggle_dark_mode"></span>
								<div class="admin_active" id="header_admin">
									<div class="header_avatar">
										<div class="popup-notification">
											<div class="notification" v-if="$cookie.get('wallet_address') !== null" @click="does_show_popup()">
												<span class="number" v-show="notification_number > 0">{{ notification_number }}</span>
												<i class="far fa-bell bd-moon-sun bd-bell" style="margin-right: 4px;"></i>
											</div>
											<div v-if="this.$root.connected == 1" class="avatar_popup2 mt-20">
												<div class="show mg-bt-18">
													<h4>Notifications</h4>
													<router-link :to="'/user/'+$root.wallet_address">Show All</router-link>
												</div>
												<div class="flat-tabs">
													
													<div class="content-tab">
														<div class="content-inner">
															<div v-for="(notification_date,notification_date_key) in notifications" class="wrap-box" :key="notification_date_key">
																<div class="heading" style="margin-bottom: 5px">{{ notification_date_key }}</div>
																<a :href="notification.link" class="sc-box" v-for="(notification, notification_key) in notification_date" :key="notification_key">
																	<div class="content" style="margin: 8px 0;">
																		<div class="avatar">
																			<img :src="notification.image" alt="">
																		</div>
																		<div class="infor">
																			<span>{{ notification.content }}</span>
																		</div>
																	</div>
																</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<template v-if="$root.connected == 1">
											<router-link :to="'/user/'+$root.wallet_address">
												<img class="avatar" v-if="user.picture == null || user.picture == 'null' || user.picture == undefined || user.picture == ''" src="/assets/images/avatar/default_avatar.png" alt="avatar"/>
												<img class="avatar" v-else :src="route_bo + '/storage/' + user.picture" alt="avatar"/>
											</router-link>
											<span class="bd-wallet-balance">{{ user.balance | reduce_number(2,'down')}} SOL</span>
											<div class="popup-user">
												<span class="fa fa-wallet bd-wallet-icon avatar"></span>
												<div class="avatar_popup mt-20">
													<h4 v-if="user.name != null && user.name != undefined">{{ user.name }}</h4>
													<h4 v-else>Unknown user #{{ user.id }}</h4>
													<div class="d-flex align-items-center mt-20 mg-bt-12">
														<div class="info">
															<p>Balance</p>
															<p class="style">{{ user.balance | reduce_number(2,'down') }} SOL</p>
														</div>
													</div>
													<p>Wallet</p>
													<div class="d-flex align-items-center justify-content-between mg-t-5 mg-bt-17">
														<p> {{ $root.wallet_address | reduce_text(4,4) }}</p>
														<a @click="$root.copy($root.wallet_address)" class="ml-2" style="color:white">
														<i class="fal fa-copy"></i>
														</a>
													</div>
													<a class="d-flex mt-10 bd-header-link" href="#" @click="wallet_logout()" id="logout">
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M9.9668 18.3057H2.49168C2.0332 18.3057 1.66113 17.9335 1.66113 17.4751V2.52492C1.66113 2.06644 2.03324 1.69437 2.49168 1.69437H9.9668C10.4261 1.69437 10.7973 1.32312 10.7973 0.863828C10.7973 0.404531 10.4261 0.0332031 9.9668 0.0332031H2.49168C1.11793 0.0332031 0 1.15117 0 2.52492V17.4751C0 18.8488 1.11793 19.9668 2.49168 19.9668H9.9668C10.4261 19.9668 10.7973 19.5955 10.7973 19.1362C10.7973 18.6769 10.4261 18.3057 9.9668 18.3057Z" fill="white"/>
															<path d="M19.7525 9.40904L14.7027 4.42564C14.3771 4.10337 13.8505 4.10755 13.5282 4.43396C13.206 4.76036 13.2093 5.28611 13.5366 5.60837L17.1454 9.16982H7.47508C7.01578 9.16982 6.64453 9.54107 6.64453 10.0004C6.64453 10.4597 7.01578 10.8309 7.47508 10.8309H17.1454L13.5366 14.3924C13.2093 14.7147 13.2068 15.2404 13.5282 15.5668C13.691 15.7313 13.9053 15.8143 14.1196 15.8143C14.3306 15.8143 14.5415 15.7346 14.7027 15.5751L19.7525 10.5917C19.9103 10.4356 20 10.2229 20 10.0003C20 9.77783 19.9111 9.56603 19.7525 9.40904Z" fill="white"/>
														</svg>
														<p style="margin-left: 10px;">Log out</p>
													</a>
												</div>
											</div>
										</template>
									</div>
								</div>
								<WalletConnect v-show="$root.connected == 0"></WalletConnect>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import WalletConnect from '@/components/WalletConnect.vue'
import {getWalletAddress,WalletLogout} from '@/libs/Wallet.js'
import {getBalance} from '@/libs/solanaConnection.js'
import axios from "axios";
import $ from "jquery";

// creating a cancel token using the CancelToken.source factory
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

// retrieving the cancel token
const token = source.token;

const cancelTokenSource = axios.CancelToken.source();

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	},
	CancelToken: source.token
}

export default {
  name: 'Header',
  components: {
    WalletConnect
  },
  props: {
    
  },
	data: function (){
		return {

			mobile_display_search : false,
			user : {},
			route_api: process.env.VUE_APP_ROOT_API,
			route_bo: process.env.VUE_APP_ROOT_BO,
			search_input: '',
			search_result: [],
			notification_number: 0,
			notifications: {},
		}
	},
  mounted: function(){

    var component_vue = this;

    this.$root.$on('connection_wallet_accept', function(){

      axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/user/' + getWalletAddress(), {}).then(async function(user) {

        component_vue.user = user.data.user;
        component_vue.$root.nfts_liked_by_user = user.data.user.liked_nfts;
        component_vue.$root.collections_liked_by_user = user.data.user.liked_collections;
        component_vue.user.balance = await getBalance(getWalletAddress());
        component_vue.$forceUpdate();

      });

    });

    component_vue.$root.$on('change_buy_nft',async function(){

      component_vue.user.balance = await getBalance(getWalletAddress());

      component_vue.$forceUpdate();

		});

    component_vue.$root.$on('change_make_offer',async function(){

      component_vue.user.balance = await getBalance(getWalletAddress());

      component_vue.$forceUpdate();

		});

    component_vue.$root.$on('change_cancel_make_offer',async function(){

        component_vue.user.balance = await getBalance(getWalletAddress());

        component_vue.$forceUpdate();

		});

    component_vue.$root.$on('change_accept_make_offer',async function(){

      component_vue.user.balance = await getBalance(getWalletAddress());

      component_vue.$forceUpdate();

		});

  },
  methods:{

    wallet_logout : function (){

			var component_vue = this;

			component_vue.$cookie.delete('user_connected_wallet');
      WalletLogout();
      component_vue.$root.nfts_liked_by_user = [];
      component_vue.$root.collections_liked_by_user = [];
      this.$root.connected = 0;
      this.$root.$emit('disconnect');
      this.$forceUpdate();

    },

    search_collection: function(){
		
		var component_vue = this;

		// canceling the previous request
		source.cancel(
			'Request canceled!' // optional
		);

		if(component_vue.search_input == "")
			component_vue.search_result = {};
		else{
			axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/collection/search/' + component_vue.search_input, config_axios).then(function(response) {

				component_vue.search_result = response.data;

			});
		}
    },

    does_show_popup : function (){

      var component_vue = this;

      if(component_vue.$root.connected == 0)
        component_vue.$root.$emit('show_modal',['connect_wallet',true]);
      else{

        axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/user/notifications_all_read/' + getWalletAddress(), {}).then(async function(notification) {

        });

      }

    },


    toggle_dark_mode() {

      var current_mode = $('body').attr('data-theme');

      var new_mode = 'dark';
			// $('body').toggleClass('is_dark');
			if(current_mode === 'dark')
        new_mode = 'light';

      this.$cookie.set('data-theme',new_mode,{ expires: '50Y' });

      this.$root.set_color_mode();
    },

  },
  created: function() {

    var component_vue = this;

    if(component_vue.$cookie.get('wallet_address') === null)
      return;

    axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/user/notifications/' + component_vue.$cookie.get('wallet_address'), {}).then(async function(notification) {

      component_vue.notification_number = notification.data.notification_number;
      component_vue.notifications = notification.data.notifications;

    });
		
  },
}
</script>

