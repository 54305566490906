<template>
	<div id="app">
		<div id="loading">
			<img src="/assets/images/loading.svg" />
		</div>
		<div id="wrapper">
			<div id="page" class="clearfix">
			
				<!-- Modales -->
				<ModalListNft :show_modal="modal.list_nft.show_modal" :nft="modal.list_nft.nft"></ModalListNft>
				<ModalListCancelNft :show_modal="modal.list_cancel_nft.show_modal" :nft="modal.list_cancel_nft.nft"></ModalListCancelNft>
				<ModalMakeOffer :show_modal="modal.make_offer.show_modal" :nft="modal.make_offer.nft" :floor_price="modal.make_offer.floor_price"></ModalMakeOffer>
				<ModalCancelMakeOffer :show_modal="modal.cancel_make_offer.show_modal" :offer="modal.cancel_make_offer.offer" ></ModalCancelMakeOffer>
				<ModalConnectWallet ref="modal_connect_wallet" :show_modal="modal.connect_wallet.show_modal"></ModalConnectWallet>
				
				<!-- Header -->              
				<Header></Header>
				
				<router-view/>
				
				<!-- Footer -->
				<Footer></Footer>
			</div>
			<!-- /#page -->

			<!-- Modal Popup Bid -->
			<div class="modal fade popup" id="popup_bid_success" tabindex="-1" role="dialog" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
						<div class="modal-body space-y-20 pd-40">
							<h3 class="text-center">Your Bidding
								Successfuly Added</h3>
							<p class="text-center">your bid <span class="price color-popup">(4ETH) </span> has been listing to our database</p>
							<a href class="btn btn-primary"> Watch the listings</a>
						</div>
					</div>
				</div>
			</div>
			<div class="modal fade popup" id="popup_bid" tabindex="-1" role="dialog" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
						<div class="modal-body space-y-20 pd-40">
							<h3>Place a Bid</h3>
							<p class="text-center">You must bid at least <span class="price color-popup">4.89 ETH</span>
							</p>
							<input type="text" class="form-control"
								placeholder="00.00 ETH">
							<p>Enter quantity. <span class="color-popup">5 available</span>
							</p>
							<input type="text" class="form-control quantity" value="1">
							<div class="hr"></div>
							<div class="d-flex justify-content-between">
								<p> You must bid at least:</p>
								<p class="text-right price color-popup"> 4.89 ETH </p>
							</div>
							<div class="d-flex justify-content-between">
								<p> Service free:</p>
								<p class="text-right price color-popup"> 0,89 ETH </p>
							</div>
							<div class="d-flex justify-content-between">
								<p> Total bid amount:</p>
								<p class="text-right price color-popup"> 4 ETH </p>
							</div>
							<a href="#" class="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Place a bid</a>
						</div>
					</div>
				</div>
			</div>
		</div>
    
	</div>
</template>

<script>

import ModalListNft from './components/Modals/ModalListNft.vue'
import ModalListCancelNft from './components/Modals/ModalListCancelNft.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import ModalMakeOffer from '@/components/Modals/ModalMakeOffer.vue';
import ModalCancelMakeOffer from '@/components/Modals/ModalCancelMakeOffer.vue';
import ModalConnectWallet from "./components/Modals/ModalConnectWallet";
import $ from 'jquery';

export default {
	name: 'App',
	components: {
    ModalConnectWallet,
		ModalListNft,
		ModalListCancelNft,
		Header,
		Footer,
    ModalMakeOffer,
    ModalCancelMakeOffer
	},
	data: function() {
		return {
			modal:{
        list_nft : {
          show_modal: false,
          nft:{},
          price: 0,
        },
        list_cancel_nft : {
          show_modal: false,
          nft:{},
        },
        make_offer: {
          show_modal:false,
          nft:{},
          floor_price: 0,
        },
        cancel_make_offer:{
          show_modal:false,
          offer:{},
        },
        connect_wallet:{
          show_modal:false,
        },
        first_connection:{
          show_modal: false,
        }
      }
		}

	},
	computed: {

	},
	mounted: function(){

    var component_vue = this;

    if(component_vue.$cookie.get('FirstConnection') == null)
      component_vue.modal.first_connection.show_modal = true;

    this.$root.$on('show_modal', function(attrs){

      if(attrs[0] == 'first_connection' && (component_vue.$refs.modalfirstcomming.$refs.show_again.checked != false && component_vue.$refs.modalfirstcomming.$refs.show_again.checked != undefined))
        component_vue.$cookie.set('FirstConnection',true, {expires: '50Y'});
      component_vue.modal[attrs[0]].show_modal = attrs[1];
    });

    if(component_vue.$cookie.get('user_connected_wallet') !== null) {
	
		setTimeout(function() {
		
			component_vue.$refs.modal_connect_wallet.connexion_wallet(component_vue.$cookie.get('user_connected_wallet'));
		}, 500);
	}
  },
}

</script>

<style>

</style>
