import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Nl2br from 'vue-nl2br'
import $ from 'jquery'
import 'dtoaster/dist/dtoaster.css'
import DToaster from 'dtoaster'
import ToasterPresets from "../public/assets/toaster/toast_presets.json"
import VueCompositionAPI from '@vue/composition-api'
import VueCookie from 'vue-cookie'
import {buy_nft_program } from '@/libs/rustProgram'
import {getSolanaObject} from "@/libs/Wallet";

Vue.component('nl2br', Nl2br)

Vue.config.productionTip = false;

Vue.use(DToaster, {
  presets: ToasterPresets,
  position: 'top-right',
  containerOffset: '45px',
});
Vue.use(VueCompositionAPI);
Vue.use(VueCookie);

Vue.filter('reduce_text', function (text = null,number_of_characters_front = 3,number_of_characters_behind = null) {

  if(text == null)
    return '';

  text = text.toString();

  var number_of_characters = number_of_characters_front;

  if(number_of_characters_behind != null)
    number_of_characters += number_of_characters_behind;

  if(text.length <= number_of_characters)
    return text;
  else if(number_of_characters_behind != null)
    return text.substring(0, number_of_characters_front) + '...' + text.substring(text.length - number_of_characters_behind, text.length);
  else
    return text.substring(0, number_of_characters_front);
})

Vue.filter('reduce_number', function (number,number_of_digits,round = 'up', empty_return = '', unity = '') {

  if(isNaN(number))
    return empty_return;
  
  if(number === false)
    return empty_return;
  
  if(number === null)
    return empty_return;

  if(round === 'up')
    return parseFloat(number.toFixed(number_of_digits))+unity;

  else {

    var proportion = Math.pow(10,number_of_digits);

    return parseFloat(Math.floor(number * proportion) / proportion)+unity;

  }

})

Vue.filter('format_number', function (number) {

    if(number === null || number === undefined || isNaN(number))
        return 0;

    var rules = {
        'b': 10,
        'm': 7,
        'k': 4,
    };

    var unit = Math.floor(parseFloat(number).toFixed());

    var number_format = '';

    var format_undone = true;

    $.each(rules,function(rule,number_limit){

        if(format_undone && unit.toString().length >= number_limit){

            var proportion = Math.pow(10,number_limit-1);

            number_format = unit/proportion;

            if(unit.toString().length === number_limit+2)
                number_format = Math.floor(number_format.toFixed());
            else
                number_format = parseFloat(number_format.toFixed(2));

            number_format = number_format.toString() + rule;

            format_undone = false;
        }

    });

    if(format_undone) {

        if(unit.toString().length === 3)
            return unit;

        return parseFloat(parseFloat(number).toFixed(2));
    }
    else
        return number_format;

})

Vue.filter('datetime', function (datetime) {

  return datetime;
})

Vue.use(require('vue-moment'));	

new Vue({
  data: {

    connected : 0,
    wallet_address : '',
    transaction_in_progress: false,
    nfts_liked_by_user: [],
    collections_liked_by_user: [],
    data_theme : 'dark',
    route_bo: process.env.VUE_APP_ROOT_BO,
    rotation_values: {},
    number_element_per_line: 'three',
  },
  created: function(){
    var component_vue = this;

    component_vue.set_color_mode();

    if(component_vue.$cookie.get('data-theme') !== null)
      component_vue.data_theme = component_vue.$cookie.get('data-theme');

    if(component_vue.$cookie.get('number-per-line') !== null)
      component_vue.number_element_per_line = component_vue.$cookie.get('number-per-line');
  },
  methods: {

    // Method loading
    loading: function(display = true) {

      if(display == true) {

          $('body').css('overflow', 'hidden');
          $('#loading').show();
      }
      else {

          $('body').css('overflow', 'auto');
          $('#loading').hide();
      }
    },

    copy: function(textToCopy) {

      var $this = this;
      navigator.clipboard.writeText(textToCopy).then(function() {
        $this.$dtoast.pop({
          preset: "success",
          heading: `Copied`,
        });
      }, function(err) {
          $this.$dtoast.pop({
            preset: "error",
            heading: `Error during copy`,
          });
      });
    },
	
	avatar: function(user) {
		
		if(user === null)
			return '/assets/images/avatar/default_avatar.png';

		if(user == undefined)
			return '/assets/images/avatar/default_avatar.png';

		if(user == '')
			return '/assets/images/avatar/default_avatar.png';

		if(user.picture == '')
			return '/assets/images/avatar/default_avatar.png';

		if(user.picture === null)
			return '/assets/images/avatar/default_avatar.png';

		return this.route_bo+'/storage/'+user.picture;
	},
	
	set_color_mode(){

      var component_vue = this;

      var theme = 'light';

      if(component_vue.$cookie.get('data-theme') !== null)
        theme = component_vue.$cookie.get('data-theme');

      $('body').attr('data-theme', theme);
    },
    number_element_per_line_cookie(new_value){

        this.number_element_per_line = new_value;

        this.$cookie.set('number-per-line',new_value,{ expires: '50Y' });
    },
    dropdown : function(id){
        var obj = $(id+'.dropdown');
		var btn = obj.find('.btn-selector');
        var dd = obj.find('ul');
        var opt = dd.find('li');
		dd.hide();
		obj.on("mouseenter", function() {
			dd.show();
			dd.addClass('show');
			$(this).css("z-index",1000);
		}).on("mouseleave", function() {
			dd.hide();
            $(this).css("z-index","auto")
            dd.removeClass('show');
		})

		opt.on("click", function() {
			dd.hide();
			var txt = $(this).text();
			opt.removeClass("active");
			$(this).addClass("active");
			btn.text(txt);
		});
    },
    rotate: function(e,name){

        var target = $(e.target);
        if(!target.hasClass('bd-button-icon-rotation')){
            target = target.find('.bd-button-icon-rotation');
        }

        if(this.rotation_values[name] === undefined)
            this.rotation_values[name] = 0;

        this.rotation_values[name] += 360;

        target.css('transform', 'rotate('+this.rotation_values[name]+'deg)');
    },

    async buy_nft(nft) {

        var component_vue = this;
		
		if (component_vue.connected == 0) {
			
            component_vue.$emit('show_modal', ['connect_wallet', true]);
		}
		else {

			component_vue.loading(true);

            var response = true;

            response = await buy_nft_program(getSolanaObject(), nft.price, nft.mint_address, nft.escrow_account).catch(
                (error) => {
                    response = "Error while confirming transaction, please refresh and try again !";
                }
            );

            if (response !== true) {

                component_vue.loading(false);

                component_vue.$dtoast.pop({
                    preset: "error",
                    heading: `Error`,
                    content: `Something went wrong, please refresh and try again`,
                });


            } else {

                setTimeout(function () {

                    component_vue.$dtoast.pop({
                        preset: "success",
                        heading: `Success`,
                        content: `Congratulations, this NFT is now yours !`,
                    });

                    component_vue.loading(false);

                    component_vue.$emit('change_buy_nft',nft.id);
                }, 2500);

            }
        }
    }
  },
  watch:{
    $route (to, from){
        var component_vue = this;
        component_vue.rotation_values = {};
        setTimeout(function(){
          component_vue.dropdown('#collection_filters');
          component_vue.dropdown('#collection_filters_sales');
          component_vue.dropdown('#collection_filters_notifications');
          component_vue.dropdown('#item_category');
          component_vue.dropdown('#buy');
          component_vue.dropdown('#all-items');
          component_vue.dropdown('#artworks');
          component_vue.dropdown('#sort-by');
          component_vue.dropdown('#sort-by2');
          component_vue.dropdown('#sort-by3');
          component_vue.dropdown('#sort-by4');
          component_vue.dropdown('#item-create');
          component_vue.dropdown('#item-create2');
          component_vue.dropdown('#item-create3');
        },250);
    }
  },
  router,
  render: h => h(App)
}).$mount('#app')
