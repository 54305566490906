import {
  Account,
  Connection,
  PublicKey,
  LAMPORTS_PER_SOL,
  SystemProgram,
  TransactionInstruction,
  Transaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js';

// import {
//   getAllNft,
//   getNftToBeUpdated,
//   updateNftHolderByMint,
// } from '@/libs/tracker.js';



import * as metadata from "@/metaplex/metadata";

import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";


import { BinaryReader, BinaryWriter } from 'borsh';
import base58 from 'bs58';
import { StringPublicKey } from '@/metaplex/ids.js';

function extendBorsh() {
  (BinaryReader.prototype).readPubkey = function () {
    const reader = this;
    const array = reader.readFixedArray(32);
    return new PublicKey(array);
  };

  (BinaryWriter.prototype).writePubkey = function (value: PublicKey) {
    const writer = this;
    writer.writeFixedArray(value.toBuffer());
  };

  (BinaryReader.prototype).readPubkeyAsString = function () {
    const reader = this;
    const array = reader.readFixedArray(32);
    return base58.encode(array);
  };

  (BinaryWriter.prototype).writePubkeyAsString = function (
    value: StringPublicKey,
  ) {
    const writer = this;
    writer.writeFixedArray(base58.decode(value));
  };
}

extendBorsh();

var TOKEN_METADATA_PROGRAM_ID = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');


let connection: Connection;
let rpcUrl;

/**
 * Establish a connection to the cluster
 */
export async function establishConnection(network): Promise<void> {
	
	if(network == 'prod') {
		
		// rpcUrl = "https://solana-api.projectserum.com";
		// rpcUrl = "https://solana-mainnet.phantom.tech";
		// rpcUrl = "https://api.mainnet-beta.solana.com";
		rpcUrl = "https://quiet-divine-breeze.solana-mainnet.quiknode.pro/7c143075abf36d094fb407f2edfe68d382e84631/";
	}
	else {
		
		// rpcUrl = "https://api.devnet.solana.com";
		// rpcUrl = "https://api.mainnet-beta.solana.com";
		rpcUrl = "https://quiet-divine-breeze.solana-mainnet.quiknode.pro/7c143075abf36d094fb407f2edfe68d382e84631/";
	}

	//connection = new Connection(rpcUrl, 'finalized');
	connection = new Connection(rpcUrl, 'confirmed');

	const version = await connection.getVersion();

	return connection;
}

export async function getNftOwner(mint_address) {
	
	await establishConnection();
	
	return new Promise(resolve => {
	
		connection.getTokenLargestAccounts(new PublicKey(mint_address)).then(function(resultat) {
			
			resultat.value.forEach(function(owner) {
				
				if(owner.amount == 1) {
					
					connection.getParsedAccountInfo(owner.address).then(function(resultats_account) {
						
						var owner = resultats_account.value.data.parsed.info.owner;
						
						return resolve(owner);
					});
				}
			});
		});
	});
}


export async function getTokenAccountForMintAddress(mint_address) {
	
	var accounts = await connection.getTokenLargestAccounts(new PublicKey(mint_address));
	
	var token_account = false;
	
	accounts.value.forEach(function(account) {
		
		if(account.amount == 1)
			token_account = account.address;
	});
	
	return token_account;
}



export async function getNftOwnerAccount(mint_address) {
	
	await establishConnection();
	
	return new Promise(resolve => {
	
		connection.getTokenLargestAccounts(new PublicKey(mint_address)).then(function(resultat) {
			
			resultat.value.forEach(function(owner) {
				
				if(owner.amount == 1) {
					
					return resolve(owner.address);
				}
			});
		});
	});
}

export async function getNftOwned(wallet_address) {
	
	await establishConnection();
	
	return new Promise(resolve => {
		
		connection.getParsedTokenAccountsByOwner(new PublicKey(wallet_address), {programId: TOKEN_PROGRAM_ID}).then(function(tokens){
			
			var nftList = [];
			
			tokens.value.forEach(function(token) {
				
				if(token.account.data.parsed.info.tokenAmount.amount == 1)
					nftList.push(token.account.data.parsed.info.mint);
			})
			
			return resolve(nftList);
		});
		
		
	});
}

export async function getParsedTokenAccountsByOwner(owner) {
	
	await establishConnection();
	
	return new Promise(resolve => {
		
		connection.getParsedTokenAccountsByOwner(owner, {programId: TOKEN_PROGRAM_ID}).then(function(tokens){
			
			var nftList = [];
			
			tokens.value.forEach(function(token) {
				
				if(token.account.data.parsed.info.tokenAmount.amount == 1)
					nftList.push(token.account.data.parsed.info.mint);
			})
			
			return resolve(nftList);
		});
	});
}

// export function getNftTokenAccountOwners() {
//
// 	return new Promise(resolve => {
//
// 		var tokenAccountOwners = [];
//
// 		getNftToBeUpdated().then(function(mints) {
//
// 			var nftAccountToFetch = mints.length;
//
// 			mints.forEach(function(mint) {
//
// 				getNftOwnerAccount(mint.mint).then(function(tokenAccountOwner) {
//
// 					tokenAccountOwners.push(tokenAccountOwner);
//
// 					if(tokenAccountOwners.length == nftAccountToFetch)
// 						return resolve(tokenAccountOwners);
// 				});
// 			})
// 		});
// 	});
// }

export async function getOwnersFromTokenAccounts(accountsPublicKey) {
	
	var accountsPublicKeyArray = [];
	
	accountsPublicKey.forEach(function(key) {
		
		var publicKeyString = key.toString();

		accountsPublicKeyArray.push(new PublicKey(key.toString()));
	});
	
	return new Promise(resolve => {
	
		connection.getMultipleAccountsInfo(accountsPublicKeyArray).then(function(parsedAccounts) {
			
			var owners = [];
			
			parsedAccounts.forEach(function(account) {
				
				var parsedAccount = metadata.decodeMetadata(account.data);

				owners.push(account.owner.toString());
			})
			
			return resolve(owners);
		});
	});
}

export async function getNftByProgram(updateAuthority, symbol) {
	
	await establishConnection('prod');
	
	var config = {
		filters: [
			{
			memcmp: {
					offset: 1,
					bytes: updateAuthority,
				}
			}
		]
	};
	
	return new Promise(resolve => {
		
		var tokens = connection.getParsedProgramAccounts(TOKEN_METADATA_PROGRAM_ID, config).then(function(result) {
	
			var liste_mint = [];
			
			result.forEach(function(token, ordre) {
			
				var data = metadata.decodeMetadata(token.account.data);
				
				if(data.data.symbol != symbol)
					return;
				
				// liste_mint.push({mint: data.mint, name: data.data.name, picture: data.data.uri});
				liste_mint.push(data);
			});
			
			return resolve(liste_mint);
		});
		
		
	});
}

export async function getBalance(mint_address){

	await establishConnection();

	return new Promise(resolve => {

		connection.getBalance(new PublicKey(mint_address)).then(function(resultat) {

			return resolve(resultat / 1000000000);

		});
	});

}

export async function findAssociatedTokenAddress(wallet_address, mint_address) {
	
	const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
		'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL',
	);

    return (await PublicKey.findProgramAddress(
        [
            wallet_address.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            mint_address.toBuffer(),
        ],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    ))[0];
}

export async function fetchAccount(publicKey, program) {
	
	let account = await program.account.escrowAccount.fetch(publicKey);

	return account;
}

export async function fetchAccountOffer(publicKey, program) {

	let account = await program.account.escrowAccountOffer.fetch(publicKey);

	return account;
}


