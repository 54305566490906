<template>
	<div v-show="show_modal === true" :class="{'bd-desktop': this.only_desktop === true}">
		<transition name="modal">
			<div class="modal-mask">
				<div class="modal-content" :class="class_modal" :style="style_modal">
					<div class="modal-header">
						<h2 class="modal-title">
              <slot name="title"></slot>
            </h2>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$root.$emit('show_modal',[name_modal,false])">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
            <slot name="body"></slot>
					</div>
					<div class="modal-footer">
            <slot name="footer"></slot>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>

export default {
	name: 'Modal',
	props: {
		show_modal: Boolean,
		only_desktop: Boolean,
		class_modal: String,
		style_modal: String,
		name_modal:String,
	},
}
</script>

