<template>
  <Modal :show_modal="show_modal" name_modal="connect_wallet" style_modal="height:50%;width:500px;min-height:500px">
    <template v-slot:title>Choose a wallet</template>
    <template v-slot:body>
      <div class="bd-textbloc">
        <div class="meta-item-details">
          <div class="item-style-2 item-details" style="display: flex;cursor: pointer;" @click="connexion_wallet('phantom')">
            <div class="wallet-connect-logo">
              <i class="wallet-logo"><img src="/assets/images/phantom.jpg"/></i> 
            </div>
            <div class="wallet-connect-name">
              <h3>Phantom</h3>
            </div>
          </div>
          <div class="item-style-2 item-details" style="display: flex;cursor: pointer;" @click="connexion_wallet('slope')">
            <div class="wallet-connect-logo">
              <i class="wallet-logo"><img src="/assets/images/slope.png"/></i> 
            </div>
            <div class="wallet-connect-name">
              <h3>Slope</h3>
            </div>
          </div>
          <div class="item-style-2 item-details" style="display: flex;cursor: pointer;" @click="connexion_wallet('solflare')">
            <div class="wallet-connect-logo">
              <i class="wallet-logo"><img src="/assets/images/solflare.svg"/></i> 
            </div>
            <div class="wallet-connect-name">
              <h3>Solflare</h3>
            </div>
          </div>
			
          <div class="item-style-2 item-details" style="display: flex;cursor: pointer;" @click="connexion_wallet('sollet')">
            <div class="wallet-connect-logo">
              <i class="wallet-logo"><img src="/assets/images/sollet.jpg"/></i>
            </div>
            <div class="wallet-connect-name">
              <h3>Sollet</h3>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/Modal.vue';
import axios from "axios";
import {connexion_with_slope,connexion_with_sollet} from '@/libs/Wallet.js';
import { Connection, clusterApiUrl } from '@solana/web3.js';
import Wallet from '@project-serum/sol-wallet-adapter';


let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

export default {
	name: 'ModalConnectWallet',
  components: {
		Modal
	},
	props: {
		show_modal: Boolean,
	},
  methods:{
    connexion_wallet : async function (wallet_name){

      var component_vue = this;

      if(wallet_name == 'phantom' && getProviderPhantom() == undefined)
        return;

      else if(wallet_name == 'solflare' && getProviderSolflare() == undefined)
        return;

      else if(wallet_name == 'sollet' ){

        var sollet_wallet = await connexion_with_sollet();

        if(sollet_wallet != null && sollet_wallet.connected === false)
          return;

        component_vue.$cookie.set('user_connected_wallet', 'sollet', { expires: '15min' });
        return component_vue.connexion_wallet_connect(sollet_wallet.publicKey.toString());
      }

      else if(wallet_name == 'slope'){

        var slope_wallet = await connexion_with_slope();

        if (slope_wallet === false){
          window.open('https://slope.finance', '_blank');
          return;
        }
        else{

          component_vue.$cookie.set('user_connected_wallet', 'slope', { expires: '15min' });
          return component_vue.connexion_wallet_connect(slope_wallet.publicKey.toString());
        }
      }

      component_vue.$cookie.set('user_connected_wallet', wallet_name, { expires: '15min' });

      var provider_name = '';

      if(wallet_name == 'phantom')
        provider_name = window.solana;
      
      else if(wallet_name == 'solflare')
        provider_name = window.solflare;

      provider_name.connect();

      provider_name.on("connect", () => {

        var wallet_address = '';

        if(wallet_name == 'phantom')
          wallet_address = provider_name.publicKey.toBase58();
        else if(wallet_name == 'solflare' || wallet_name == 'sollet')
          wallet_address = provider_name.publicKey.toString();

        return component_vue.connexion_wallet_connect(wallet_address);
      });
    },

    connexion_wallet_connect : function (wallet_address){
      
      var component_vue = this;

      component_vue.$root.wallet_address = wallet_address;
      component_vue.$root.connected = 1;
      component_vue.$root.$emit('show_modal',['connect_wallet',false]);
      component_vue.$root.$emit('connection_wallet_accept');
      component_vue.$cookie.delete('wallet_address');
      component_vue.$cookie.set('wallet_address', wallet_address, {expires: '50Y'});

      axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/user/'+wallet_address, {}, config_axios);
      component_vue.$forceUpdate();
    },
  },
}

const getProviderPhantom = () => {

  if ("solana" in window) {

    const provider = window.solana;

    if (provider.isPhantom) {

      return provider;

    }

  }

  window.open("https://phantom.app/", "_blank");

};

const isSolflareInstalled = window.solflare && window.solflare.isSolflare;

const getProviderSolflare = () => {

  if ('solflare' in window) {

    const provider = window.solflare;

    if (provider.isSolflare) {

      return provider;

    }
  }
  window.open('https://solflare.com', '_blank');
};
</script>